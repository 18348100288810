<script>
import AuthService from '@/service/auth.service';
import { mapActions } from 'vuex';

export default {
  data: () => ({
    form: {
      email: '',
    },

    showPass: false,
    loading: false,
  }),

  methods: {
    ...mapActions('auth', ['login']),

    async submit() {
      this.loading = true;

      try {
        const { email } = this.form;
        await AuthService.confirmEmailSend({ email });
        this.$toastr.success(
          'Acesse seu email e siga as instruções para confirmar seu email.',
          'Email de confirmação enviado!',
        );
        // this.$router.push('/login');
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },

    clear() {
      this.form = {
        email: '',
        password: null,
      };
      this.$refs.form.reset();
    },
  },
};
</script>

<template>
  <div class="px-5 px-sm-12">
    <div>
      <h1 class="text-center">Confirme seu email</h1>
      <h5 class="text-center mt-3">Enviaremos um email com instruções para confirmar seu email</h5>

      <v-form ref="form" class="mt-15" @submit.prevent="submit">
        <div class="text-center">
          <v-btn type="submit" x-large block color="primary" :loading="loading"> Re-enviar email </v-btn>
        </div>
      </v-form>

      <div class="mt-10 text-center">
        <router-link to="/login">Voltar para o login</router-link>
      </div>
    </div>
  </div>
</template>
